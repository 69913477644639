import React from 'react';
import { Header } from './componennts/Header/Header';
import { Foother } from './componennts/Foother/Foother';
import { Main } from './componennts/Main/Main';
import { Routes,Route } from "react-router-dom"
import { Login } from './componennts/Login/Login';
import { Blog } from './componennts/Blog/Blog';
import { BlogItem } from './componennts/BlogItem/BlogItem';
import { Analytics } from './componennts/Analytics/Analytics';
import { Payooner } from './componennts/Payooner/Payooner';
import { ACH } from './componennts/ACH/ACH';
import { OurServices } from "./componennts/OurServices/OurServices";
import { Reviews } from "./componennts/Reviews/Reviews";
import { MainServices } from "./componennts/MainServices/MainServices";
import { LastProjects } from "./componennts/LastProjects/LastProjects";
import { Payment } from "./componennts/Payment/Payment";
import CustomDevelop  from "./componennts/CustomDevelop/CustomDevelop";
import Recriting from "./componennts/Recriting/Recriting";
import Design from "./componennts/Design/Design";
import {Marketing} from "./componennts/Marketing/Marketing";


function App() {
  return (
    <div className="App">
      <Header/>
      <main>
        <Routes>
            <Route  path='/' element={<Main/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/blog' element={<><Blog/><Foother/></>}/>
            <Route path='/blog/*' element={<><BlogItem/><Foother/></>}/>
            <Route path='/analytics' element={<><Analytics/><Foother/></>}/>
            <Route path='/payment' element={<><Payment/><Foother/></>}/>
            <Route path='/payooner' element={<><Payooner/><Foother/></>}/>
            <Route path='/ach' element={<><ACH/><Foother/></>}/>
            <Route path='/benefits' element={<><OurServices/><Foother/></>}/>
            <Route path='/reviews' element={<><Reviews/><Foother/></>}/>
            <Route path='/product/mainservices' element={<><MainServices/><Foother/></>}/>
            <Route path='/projects' element={<><LastProjects/><Foother/></>}/>
            <Route path='/product/customdevelop' element={<><CustomDevelop/><Foother/></>}/>
            <Route path='/product/recruting' element={<><Recriting/><Foother/></>}/>
            <Route path='/product/design' element={<><Design/><Foother/></>}/>
            <Route path='/product/marketing' element={<><Marketing/><Foother/></>}/>
        </Routes>
      </main>

    </div>
  );
}

export default App;

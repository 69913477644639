import React, {useState} from "react";
import './CustomDevelop.css'
import CustomDevelop1 from "../../img/CustomDevelop1.png";
import CustomDevelop2 from "../../img/CustomDevelop2.png";
import CustomDevelop3 from "../../img/CustomDevelop3.png";
import Website from "../../img/Custom-Website.jpeg";
import migratie from "../../img/data-migratie.png";
import MobileApp from "../../img/MobileApp.png";
import integration from "../../img/systems-integration.png";
import product from "../../img/product-development.jpeg";
 const CustomDevelop = () => {
     const [popUp,setPopUp] = useState<boolean>(false)
    return (
        <div className="MainServices-wrapper">
            <div className="MainServices-Promo">
                <div  data-aos="zoom-out"
                      data-aos-duration="1500" className="Promo-content">
                    <div className="Promo-title">
                        Custom
                        <div style={{color:"#0b73c4",marginLeft:'5px'}}>Develop</div>
                    </div>
                    <div className="Promo-pretitle">
                        Crafting Digital Experiences: <br/> Custom Development at Its Best
                    </div>
                    <div className="flex-row-gap-75">
                        <input className="Promo-btn-primary" type="button" value="Launch"/>
                        <input className="Promo-btn" type="button" value="Promo video"/>
                    </div>
                </div>
                <div className="Promo-bg-elements">
                    <img data-aos="fade-right"
                         data-aos-duration="1500"  id="facebook"  src={CustomDevelop1} alt=""/>
                    <img  data-aos="fade-left"
                          data-aos-duration="1500"id="telegram"  src={CustomDevelop2} alt=""/>
                    <img data-aos="fade-right"
                         data-aos-duration="1500"  id="reddit"  src={CustomDevelop3} alt=""/>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className="content-image">
                    <img src={Website} alt=""/>
                </div>
                <div data-aos="fade-left"
                     data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Custom website
                    </div>
                    <div className="content-text">
                        Custom websites offer a level of site flexibility and functionality. Professional developer or designer will deal with ongoing site maintenance, and SEO expert consult you to ensure your site ranks well on search engines.
                    </div>
                    <div className="content-text">
                        A custom website is the right choice if you have specific design requirements or need special features. If you’re running a large business or an eCommerce store with a large inventory, a custom website might be necessary. While the initial and maintenance costs can be higher, the results can positively impact your conversion rates and overall business performance.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>

            <div className="content">
                <div className="content-image">
                    <img src={migratie} alt=""/>
                </div>
                <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        Data migration
                    </div>
                    <div className="content-pretitle">
                        This is a process of moving digital information—often from on-premises locations to a cloud platform.
                    </div>
                    <div className="content-text">
                        We deal with transferring needed information to a different location, file format, storage system, database, or application.
                        Data assets can be found in various states and locations, leading to differing levels of complexity and technical challenges in migration projects. That's why only expert should do this as such projects require planning, implementation, and validation to ensure their success.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className='content-image'>
                    <img src={integration} alt=""/>
                </div>
                <div  data-aos="fade-right"
                      data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        System Integration
                    </div>
                    <div className="content-pretitle">
                        Primarily needed to increase the profit obtained from an employee, specifically by minimizing the time they spend on routine tasks.
                    </div>
                    <div className="content-text">
                        It involves lead transfer to CRM or another system, sending data for cross-analytics, email/call tracking, using tools like GetCall, and data synchronization with the system.
                        It is essential for companies heavily reliant on the efficiency of their frontline staff.
                    </div>
                    <div className="content-text">
                        Furthermore, it not only enhances employee efficiency but also allows for more in-depth monitoring of their actions and activities.
                        Pricing is situational and depends largely on the complexity and scope of work.
                    </div>
                    <div className="content-text">
                        If you're interested, click the button, and we will get in touch with you to discuss the project.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content">
                <div className='content-image'>
                    <img src={MobileApp} alt=""/>
                </div>
                <div data-aos="flip-left"
                     data-aos-easing="ease-out-cubic"
                     data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        Мobile App Development
                    </div>
                    <div className="content-pretitle">
                        This is the realization of your ideas on Android and iOS platforms.
                        This development option is especially worth paying attention to because year after year, more and more users browse information from their phones. Furthermore, screen time on mobile devices continues to rise.
                    </div>
                    <div className="content-text">
                        Why should you turn to us?
                    </div>
                    <div className="content-text">
                        It's simple. Firstly, we are an outsourcing company, so our prices are lower than on the US market.

                    </div>
                    <div className="content-text">
                        Secondly, although our company has been in existence for less than a year, we have already made a name for ourselves. And the number of companies that have turned to us is already well over a hundred.
                        This is mainly due to the high quality of the final product that we provide.
                        If you are interested in this service, please write to us. We would be delighted to have you as our client.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className='content-image'>
                    <img src={product} alt=""/>
                </div>
                <div  data-aos="fade-right"
                      data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Custom SaaS Product Development
                    </div>
                    <div className="content-pretitle">
                        This is a service for creating individual software services that provide various functional capabilities for specific types of business tasks. The main advantages of developing such software with us are that we guarantee:
                    </div>
                    <div className="content-text">
                        Full uniqueness of the final product.
                        Ongoing support and software updates.
                        Data security of any kind.
                    </div>
                    <div className="content-text">
                        Custom SaaS product development is a solution for companies that not only aim to optimize their business processes but also seek opportunities to provide unique solutions to their employees and customers.

                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            {
                popUp && <div className="product-lead-form">
                    <div className='flex-row-lead'>
                        <div className="lead-title">
                            <div style={{color:'#0b73c4'}}>Let's discuss</div> your project
                            <div className="lead-pre-title">
                                Fill out the form and  <br/>we will contact you within a day
                            </div>
                        </div>
                        <button onClick={()=>setPopUp(false)} className="lead-close">
                            X
                        </button>
                    </div>
                    <form className="lead-form">
                        <input className="lead-input" type="text" placeholder="First and Last name"/>
                        <input className="lead-input" type="text" placeholder="Phone number"/>
                        <input className="lead-input" type="text" placeholder="Email"/>
                        <input className="lead-button" type="button" value="Send the application"/>
                    </form>
                </div>
            }
        </div>
    )
}
export default CustomDevelop;

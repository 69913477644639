import React, {useState} from 'react'
import "../../index.css"
import './Payment.css'
import cub from "../../img/Cub_logo.png"
import {Paypal} from "../Paypal/Paypal";
import {Payooner} from "../Payooner/Payooner";
import {ACH} from "../ACH/ACH";

export const Payment = () => {
    const [PaymentSelect,setPayment] = useState<'Paypal'|'Payooner'|'ACH'>('Paypal')
    return (
        <div  className='payment-wrapper'>
            <div data-aos="fade-down"
                 data-aos-duration="1500" className="payment-form" >
                <div className="payment-flex-row">
                    <input onClick={()=>setPayment("Paypal")} className={PaymentSelect ==="Paypal"?"payment-btn":"payment-btn-non-active"} type="button" value="Pay Pal"/>
                    <input onClick={()=>setPayment("ACH")} className={PaymentSelect==="ACH"?"payment-btn":"payment-btn-non-active"} type="button" value="ACH"/>
                    <input onClick={()=>setPayment("Payooner")} className={PaymentSelect==="Payooner"?"payment-btn":"payment-btn-non-active"} type="button" value="Payooner"/>
                </div>
                {PaymentSelect === "Paypal"?<Paypal/>:<></>}
                {PaymentSelect === "ACH"?<ACH/>:<></>}
                {PaymentSelect === "Payooner"?<Payooner/>:<></>}

            </div>
            <div>
                <img className="cube-imege" src={cub} alt="" />
            </div>
        </div>
    )
}






import React from "react";
import {Carousel} from "antd";
import "./Reviews.css"
export const Reviews = () => {
    return(
        <div className="rewiews-wrapper">
            <div   data-aos="zoom-in"
                   data-aos-duration="1500">
                <h2 className="h2">What clients think about us</h2>
                <div className="comment-big">
                    <Carousel id='comment'   autoplay >
                        <div>
                            <div className=" flex-col-wrap ">
                                <div data-w-id="a9f06dd0-7c5e-b9ca-964b-5580bef6fadd"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color isans"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Loretis Andriuskevicius<span className="text-span-3">GTS Transportation</span></div>
                                            <div className="cta-testimonials">Nice Team!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">They help us find drivers in short time and we were glad to cooperate with this affordable company with nice team.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials"><span className="text-span-3">Sam Express</span></div>
                                            <div className="cta-testimonials">Everything is cool!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">G&M is a company with great communication and service. I promise you that their ideas help my company in difficult time. Just for the record, they worth all good word I told about them.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Igor<span className="text-span-3">ProQuality Transportation & Logistics</span></div>
                                            <div className="cta-testimonials">Responsible team!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">I’ve talked with manager Peter, who politely assisted me during our cooperation. What’s more, they developed a delightful website for us taking into account all the details of our field of work. As I know, they work with many logistic companies and that’s why they are competent in it.<br/></div>
                                </div>
                                <div data-w-id="1c9dd5d8-a141-1ed4-23b2-b36aa9abfbb9"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color mvs-freight"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Kirill Dorokhov<span className="text-span-3"></span></div>
                                            <div className="cta-testimonials">I am satisfied with the software!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">This company knows what to do and I highly recommend it as a great investigation in your business. I got excellent result working with them, so don’t hesitate to contact them!<br/></div>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div className=" flex-col-wrap container">
                                <div id="w-node-_0797ed6e-33cc-461d-cf0f-e4efb498ba7e-5b8bed2b" data-w-id="0797ed6e-33cc-461d-cf0f-e4efb498ba7e"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div id="w-node-_0797ed6e-33cc-461d-cf0f-e4efb498ba80-5b8bed2b" className="otz-ava color canada-drives"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Mariana Garstea<span className="text-span-3">Universe Carrier</span></div>
                                            <div className="cta-testimonials">Good Guys!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">It has been a pleasure working with G&M because the most important issue for us is quality and they deal with it. I know that this company work hard and for result.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials"><span className="text-span-3">NORTHWEST MOTORS</span></div>
                                            <div className="cta-testimonials">I really liked the result!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">All managers are always friendly with you and you really enjoyed the service in these guys. They provided me all I wanted quickly and without misunderstandings…as it was earlier with other company. So, I was glad to work with them in such pleasant way.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials"><span className="text-span-3">TechStart.dev</span></div>
                                            <div className="cta-testimonials">Safe and secure!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">My experience with G&M Development has been excellent. We received nice service and support during our work together. I hope, you will also get the same professional and successful service as got. Thank all your staff for giving me that result.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Mykhailo \ Michael<span className="text-span-3">ALPHA GLASS GROUP</span></div>
                                            <div className="cta-testimonials">Everything works as it should!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">After our cooperation we had remarkable number of clients. It’s IT professionals that do business in safe and reliable way.<br/></div>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div className="  flex-col-wrap container">
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Nick Chernomorets<span className="text-span-3">OCMC Trucking Inc</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">We were looking for drivers with them and their program really works. Alex, who communicated with me, was always responsive to my inquiries, and kept me informed about the progress at every stage. They provide transparent and responsible service.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Alla Dolbina<span className="text-span-3">Adolbi Inc GROUP</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">
                                        I worked with G&M to develop a website for my business, and I must say that this guys are professionals!
                                        Also, I want to mark their exceptional communication and web development services throughout the entire project, especially manager Alex, who led during it. I am fully satisfied with their services, and I hope to collaborate with them on future projects.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Denys<span className="text-span-3">VilniVirni GROUP</span></div>
                                            <div className="cta-testimonials">Everything works as it should!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">Company work for clients. The manager Peter, who communicate with me was constantly in touch and guided me through the process. They develop a website and provide outstanding marketing services to drive traffic and conversions. It is create a great combo for my potential customers.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Anastasiia<span className="text-span-3">Smart Freight Express Inc</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">Communication with Peter was easy and pleasant and he choose a profitable solution for our problem (and it's greate to have a tech-savvy friend in the business who's got your back). His professionalism, expertise, and dedication to give exceptional results have made this company a valuable partner in our business's growth.<br/></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className=" flex-col-wrap container">
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Yurii<span className="text-span-3">Veritas Cable</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">
                                        I had good results while working with this company. Their marketing strategies have been transformative for our business. Manager Ivan, he is really nice guy and provide me useful customer service. This deal was actually profitable for my company.<br/></div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className='comment-litle'>
                    <Carousel  id='comment'   autoplay >
                                <div data-w-id="a9f06dd0-7c5e-b9ca-964b-5580bef6fadd"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color isans"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Loretis Andriuskevicius<span className="text-span-3">GTS Transportation</span></div>
                                            <div className="cta-testimonials">Nice Team!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">They help us find drivers in short time and we were glad to cooperate with this affordable company with nice team.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials"><span className="text-span-3">Sam Express</span></div>
                                            <div className="cta-testimonials">Everything is cool!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">G&M is a company with great communication and service. I promise you that their ideas help my company in difficult time. Just for the record, they worth all good word I told about them.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Igor<span className="text-span-3">ProQuality Transportation & Logistics</span></div>
                                            <div className="cta-testimonials">Responsible team!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">I’ve talked with manager Peter, who politely assisted me during our cooperation. What’s more, they developed a delightful website for us taking into account all the details of our field of work. As I know, they work with many logistic companies and that’s why they are competent in it.<br/></div>
                                </div>
                                <div data-w-id="1c9dd5d8-a141-1ed4-23b2-b36aa9abfbb9"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color mvs-freight"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Kirill Dorokhov<span className="text-span-3"></span></div>
                                            <div className="cta-testimonials">I am satisfied with the software!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">This company knows what to do and I highly recommend it as a great investigation in your business. I got excellent result working with them, so don’t hesitate to contact them!<br/></div>
                                </div>
                                <div id="w-node-_0797ed6e-33cc-461d-cf0f-e4efb498ba7e-5b8bed2b" data-w-id="0797ed6e-33cc-461d-cf0f-e4efb498ba7e"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div id="w-node-_0797ed6e-33cc-461d-cf0f-e4efb498ba80-5b8bed2b" className="otz-ava color canada-drives"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Mariana Garstea<span className="text-span-3">Universe Carrier</span></div>
                                            <div className="cta-testimonials">Good Guys!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">It has been a pleasure working with G&M because the most important issue for us is quality and they deal with it. I know that this company work hard and for result.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials"><span className="text-span-3">NORTHWEST MOTORS</span></div>
                                            <div className="cta-testimonials">I really liked the result!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">All managers are always friendly with you and you really enjoyed the service in these guys. They provided me all I wanted quickly and without misunderstandings…as it was earlier with other company. So, I was glad to work with them in such pleasant way.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials"><span className="text-span-3">TechStart.dev</span></div>
                                            <div className="cta-testimonials">Safe and secure!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">My experience with G&M Development has been excellent. We received nice service and support during our work together. I hope, you will also get the same professional and successful service as got. Thank all your staff for giving me that result.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Mykhailo \ Michael<span className="text-span-3">ALPHA GLASS GROUP</span></div>
                                            <div className="cta-testimonials">Everything works as it should!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">After our cooperation we had remarkable number of clients. It’s IT professionals that do business in safe and reliable way.<br/></div>
                                </div>

                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Nick Chernomorets<span className="text-span-3">OCMC Trucking Inc</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">We were looking for drivers with them and their program really works. Alex, who communicated with me, was always responsive to my inquiries, and kept me informed about the progress at every stage. They provide transparent and responsible service.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Alla Dolbina<span className="text-span-3">Adolbi Inc GROUP</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">
                                        I worked with G&M to develop a website for my business, and I must say that this guys are professionals!
                                        Also, I want to mark their exceptional communication and web development services throughout the entire project, especially manager Alex, who led during it. I am fully satisfied with their services, and I hope to collaborate with them on future projects.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Denys<span className="text-span-3">VilniVirni GROUP</span></div>
                                            <div className="cta-testimonials">Everything works as it should!</div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">Company work for clients. The manager Peter, who communicate with me was constantly in touch and guided me through the process. They develop a website and provide outstanding marketing services to drive traffic and conversions. It is create a great combo for my potential customers.<br/></div>
                                </div>
                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"   className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Anastasiia<span className="text-span-3">Smart Freight Express Inc</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">Communication with Peter was easy and pleasant and he choose a profitable solution for our problem (and it's greate to have a tech-savvy friend in the business who's got your back). His professionalism, expertise, and dedication to give exceptional results have made this company a valuable partner in our business's growth.<br/></div>
                                </div>


                                <div data-w-id="484dcc4d-b8ae-c170-cef9-588d28194936"  className="comelement otz-fon right">
                                    <div className="w-layout-grid grid-otz">
                                        <div className="otz-ava color butler-snow"></div>
                                        <div className="otz-text-fon">
                                            <div className="owner-desription-testimonials">Yurii<span className="text-span-3">Veritas Cable</span></div>
                                            <div className="cta-testimonials"></div>
                                        </div>
                                    </div>
                                    <div className="description-text-testimonials">
                                        I had good results while working with this company. Their marketing strategies have been transformative for our business. Manager Ivan, he is really nice guy and provide me useful customer service. This deal was actually profitable for my company.<br/></div>
                                </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
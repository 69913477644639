import React, {useState} from 'react'
import "../../index.css"
import logo from "../../img/logo.svg"
import { NavLink } from "react-router-dom"
import './Header.css'
// import { Switch } from 'antd'
export const Header = () => {
    const [open,setOpen] = useState<boolean>(false)
  return (
    <div  role="banner" className="navbar-fixed w-nav">
      <div data-w-id="7ac41e27-1dd3-e3fc-f6c9-18e065f1a671" className="z flex-header w-container">
         <a href="/" className="logo-9 no-mobile w-nav-brand"><img src={logo} loading="lazy" style={{width:'80px',height:'32px'}} alt="logo" className="logo-img"/></a>

         <nav  className="nav-menu-13 w-nav-menu">
            {/*<Switch />*/}
             <div className="menu-item w-nav-link Dropdown">
                 Product
                 <div className="Dropdown-list">
                     <NavLink to={'/product/mainservices'} className="menu-item w-nav-link">Main Services</NavLink>
                     <NavLink to={'/product/customdevelop'} className="menu-item w-nav-link">Custom Develop</NavLink>
                     <NavLink to={'/product/recruting'} className="menu-item w-nav-link">Recruting</NavLink>
                     <NavLink to={'/product/design'} className="menu-item w-nav-link">Design</NavLink>
                     <NavLink to={'/product/marketing'} className="menu-item w-nav-link">Marketing</NavLink>
                 </div>
             </div>
             <NavLink to={'/reviews'} className="menu-item w-nav-link">Rewiews</NavLink>
             <NavLink to={'/benefits'} className="menu-item w-nav-link">Benefits</NavLink>
             <NavLink to={'/projects'} className="menu-item w-nav-link">Projects</NavLink>
             <NavLink to={'/payment'} className="menu-item w-nav-link">Payment</NavLink>
             <NavLink data-w-id="bde4c271-7416-02ad-7d29-77a8eea8b6b2" style={{margin:'0 .75rem'}}  className="button-18 w-button" to={'/blog'}>Blog</NavLink>
             <NavLink data-w-id="bde4c271-7416-02ad-7d29-77a8eea8b6b2" style={{margin:'0 .75rem'}} className="button-18 w-button" to={'/login'}>log in</NavLink>


         </nav>
          <button onClick={()=>setOpen(!open)} className="btn-menu">

          </button>

      </div>
        {
            open && <div className='menu-burger'>
                <details className="menu-burger-item w-nav-link ">
                    <summary>Product</summary>
                    <div >
                        <NavLink to={'/product/mainservices'} className="menu-burger-item w-nav-link">Main Services</NavLink>
                        <NavLink to={'/product/customdevelop'} className="menu-burger-item w-nav-link">Custom Develop</NavLink>
                        <NavLink to={'/product/recruting'} className="menu-burger-item w-nav-link">Recruting</NavLink>
                        <NavLink to={'/product/design'} className="menu-burger-item w-nav-link">Design</NavLink>
                        <NavLink to={'/product/marketing'} className="menu-burger-item w-nav-link">Marketing</NavLink>
                    </div>
                </details>
                <NavLink to={'/reviews'} className="menu-burger-item ">Rewiews</NavLink>
                <NavLink to={'/benefits'} className="menu-burger-item ">Benefits</NavLink>
                <NavLink to={'/projects'} className="menu-burger-item ">Projects</NavLink>
                <NavLink to={'/payment'} className="menu-burger-item ">Payment</NavLink>
                <div className='menu-for-btn'>
                    <NavLink data-w-id="bde4c271-7416-02ad-7d29-77a8eea8b6b2" style={{margin:'0 .75rem'}}  className="button-18 w-button" to={'/blog'}>Blog</NavLink>
                    <NavLink data-w-id="bde4c271-7416-02ad-7d29-77a8eea8b6b2" style={{margin:'0 .75rem'}} className="button-18 w-button" to={'/login'}>log in</NavLink>
                </div>
            </div>
        }
   </div>
  )
}

import React, {useState} from "react";
import '../CustomDevelop/CustomDevelop.css'
import CustomDevelop1 from "../../img/CustomDevelop1.png";
import CustomDevelop2 from "../../img/CustomDevelop2.png";
import CustomDevelop3 from "../../img/CustomDevelop3.png";
import VR from '../../img/VR.jpg'
import logo from '../../img/logo&brand.jpg'
import WebDesign from '../../img/WebDesign.jpg'
import VideoEdit from '../../img/VideoEdit.png'

const Design = () => {
    const [popUp,setPopUp] = useState<boolean>(false)
    return (
        <div className="MainServices-wrapper">
            <div className="MainServices-Promo">
                <div  data-aos="zoom-out"
                      data-aos-duration="1500" className="Promo-content">
                    <div className="Promo-title">
                        Design
                    </div>
                    <div className="Promo-pretitle">
                        Crafting Digital Experiences: <br/> Custom Development at Its Best
                    </div>
                    <div className="flex-row-gap-75">
                        <input className="Promo-btn-primary" type="button" value="Launch"/>
                        <input className="Promo-btn" type="button" value="Promo video"/>
                    </div>
                </div>
                <div className="Promo-bg-elements">
                    <img data-aos="fade-right"
                         data-aos-duration="1500"  id="facebook"  src={CustomDevelop1} alt=""/>
                    <img  data-aos="fade-left"
                          data-aos-duration="1500"id="telegram"  src={CustomDevelop2} alt=""/>
                    <img data-aos="fade-right"
                         data-aos-duration="1500"  id="reddit"  src={CustomDevelop3} alt=""/>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className='content-image blur-1'>
                    <img src={VR} alt=""/>
                </div>
                <div data-aos="fade-left"
                     data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Landscape Design Outdoor–Indoor VR Environments
                    </div>
                    <div className="content-text">
                        Virtual reality is a valuable tool in landscape architecture, allowing users to interactively visualize and navigate designed environments. Research comparing the perception of 3D environments in indoor and outdoor VR settings found that open VR environments offer a better overall user experience. Obstacles in closed environments limit freedom of movement and decrease the sense of presence, while outdoor lighting and shadows enhance spatial perception and immersion.
                    </div>
                    <div className="content-text">
                        Landscape design helps you with planning, architectural space design, and modifications of building and landscape environments.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>

            <div className="content">
                <div className='content-image '>
                    <img src={logo} alt=""/>
                </div>
                <div data-aos="flip-left"
                     data-aos-easing="ease-out-cubic"
                     data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        Logo Design and Brand Identity
                    </div>
                    <div className="content-pretitle">
                        A logo and brand idenity play a key role in developing a company image and should be crafted with meticulous planning, thorough research, and keen attention to detail.
                    </div>
                    <div className="content-text">
                        Investing in a professional design and branding agency to design a logo and create brand idenity is a wise decision that yields long-term benefits.
                    </div>
                    <div className="content-text">

                        A complete brand identity encompasses everything about a company, including its aspirations, values, attitudes, actions, the involvement of board members, employees, and customers, and this should collectively work together.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className='content-image blur-1'>
                    <img src={WebDesign} alt=""/>
                </div>
                <div  data-aos="fade-right"
                      data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Web design
                    </div>
                    <div className="content-pretitle">
                        In website design, it's crucial to strike a balance between aesthetics and functionality. Usability encompasses factors like user-friendly navigation, judicious use of visuals, coherent and well-placed content, and a harmonious color palette. On the other hand, performance pertains to speed, search engine ranking, discoverability, and the site's capability to engage your audience.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className='content-image blur-1 '>
                    <img src={VideoEdit} alt=""/>
                </div>
                <div  data-aos="fade-right"
                      data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Video editing
                    </div>
                    <div className="content-pretitle">
                        Whether you're a YouTuber, TikTok creator, or part of a professional movie studio, we've analyzed and categorized the best video editing software options according to their features, capabilities, and pricing.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            {
                popUp && <div className="product-lead-form">
                    <div className='flex-row-lead'>
                        <div className="lead-title">
                            <div style={{color:'#0b73c4'}}>Let's discuss</div> your project
                            <div className="lead-pre-title">
                                Fill out the form and  <br/>we will contact you within a day
                            </div>
                        </div>
                        <button onClick={()=>setPopUp(false)} className="lead-close">
                            X
                        </button>
                    </div>
                    <form className="lead-form">
                        <input className="lead-input" type="text" placeholder="First and Last name"/>
                        <input className="lead-input" type="text" placeholder="Phone number"/>
                        <input className="lead-input" type="text" placeholder="Email"/>
                        <input className="lead-button" type="button" value="Send the application"/>
                    </form>
                </div>
            }
        </div>
    )
}
export default Design;

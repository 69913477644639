import { useDispatch } from 'react-redux';
import { configureStore} from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
  },
})

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>



import React, {useState} from 'react'
import '../MainServices/MainServices.css'
import analyticsINST from "../../img/InstagramAnatytics.png"
import analyticsFB from "../../img/FBAnalytics.png"
import analyticsGf04 from "../../img/Gfo4Analytics.png"
import Ads from '../../img/ADS.png'
import SEOPC from '../../img/SEO-P&C.jpeg'
import target from '../../img/local_target.jpg'
import SMM from '../../img/SMM.webp'
export const Marketing = () => {
    const [popUp,setPopUp] = useState<boolean>(false)
    return (
        <div className="MainServices-wrapper">
            <div className="MainServices-Promo">
                <div data-aos="zoom-out"
                     data-aos-duration="1500" className="Promo-content">
                    <div className="Promo-title">
                        Marketing
                    </div>
                    <div className="Promo-pretitle">
                        Create personalized campaigns with unlimited follow-ups to grow conversion rate and average order value.
                    </div>
                    <div className="flex-row-gap-75">
                        <input className="Promo-btn-primary" type="button" value="Launch"/>
                        <input className="Promo-btn" type="button" value="Promo video"/>
                    </div>
                </div>
            </div>
            <div className="Promo-bg-elements">
                <img data-aos="fade-right"
                     data-aos-duration="1500" id="facebook"  src={analyticsFB} alt=""/>
                <img data-aos="fade-left"
                     data-aos-duration="1500" id="telegram"  src={analyticsINST} alt=""/>
                <img data-aos="fade-right"
                     data-aos-duration="1500" id="reddit"  src={analyticsGf04} alt=""/>
            </div>

            <div className="content-bg-f2" >
                <div className="content-image">
                    <img src={SMM} alt=""/>
                </div>
                <div data-aos="flip-left"
                     data-aos-easing="ease-out-cubic"
                     data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        SMM stands for promoting a specific trade mark, brand, company, or organization using social media content.
                    </div>
                    <div className="content-pretitle">
                        Advantages of SMM:
                    </div>
                    <div className="content-text">
                        Wider and clearer coverage of the target audience and a specific consumer segment.
                    </div>
                    <div className="content-text">
                        Studying the level of interest of consumers in your brand specifically.
                    </div>
                    <div className="content-text">
                        Increasing the conversion rates of existing advertising campaigns.
                    </div>
                    <div className="content-pretitle">
                        Why choose us?
                    </div>
                    <div className="content-text">
                        We have the best content creators who will fill your social media accounts with high-quality live photos and stories.
                    </div>
                    <div className="content-text">
                        Most importantly, you will notice a significant result that this content will achieve by enhancing the recognition and reputation of your company.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className="content-image">
                    <img src={target} alt=""/>
                </div>
                <div data-aos="fade-left"
                     data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Target
                    </div>
                    <div className="content-pretitle">
                        We use products from the Meta company.
                    </div>
                    <div className="content-text">
                        It allows for gender and age-based targeting and, with the right approach, delivers excellent results.
                        However, the market is flooded with low-qualified marketers.
                        That's why you should connect with us.
                        Firstly, we have experience working successfully with complex niches, both B2B and challenging B2C niches (such as real estate sales and niches with a high initial customer ticket).
                        Secondly, we won't feed you breakfasts; we'll provide results from the first month of work!
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>

            <div className="content">
                <div className="content-image">
                    <img src={Ads} alt=""/>
                </div>
                <div data-aos="flip-left"
                     data-aos-easing="ease-out-cubic"
                     data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        Google and Microsoft Ads Contextual Advertising
                    </div>
                    <div className="content-pretitle">
                        Global advertising that allows you to achieve mass exposure and high engagement with your target audience.
                    </div>
                    <div className="content-text">
                        However, it's a highly competitive form of advertising that requires significant attention and meticulous work.
                        That's why you should get in touch with us.
                    </div>
                    <div className="content-text">
                        Firstly, we tailor the advertising primarily to your target audience and genuinely guarantee the quantity of leads, specifically targeted leads.
                    </div>
                    <div className="content-text">
                        Secondly, for this type of advertising, we work with a large number of companies, especially dealerships in the USA, and have enough experience to guarantee the results you are counting on. Of course, if they are realistic.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className="content-image">
                    <img src={SEOPC} alt=""/>
                </div>
                <div  data-aos="fade-right"
                      data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        SEO Website Promotion & Cloaking
                    </div>
                    <div className="content-text">
                        SEO website promotion is essentially the optimization of a website to meet the requirements of search engines. This includes developing a strategy, creating content, optimizing, and analyzing the results obtained. The goal is to improve visibility and elevate the website's ranking in search results.
                    </div>
                    <div className="content-text">
                        Cloaking is a variation of SEO, but in this case, it involves using a kind of dual page. At the top, there is a beautifully designed page, while at the bottom, there is simply a set of keywords that search bots read. It is a more affordable and quicker SEO option. However, it is not considered entirely ethical from the perspective of corporations.
                    </div>
                    <div className='content-text'>
                        The price is situational and heavily depends on the competitiveness of the niche.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            {
                popUp && <div className="product-lead-form">
                    <div className='flex-row-lead'>
                        <div className="lead-title">
                            <div style={{color:'#0b73c4'}}>Let's discuss</div> your project
                            <div className="lead-pre-title">
                                Fill out the form and  <br/>we will contact you within a day
                            </div>
                        </div>
                        <button onClick={()=>setPopUp(false)} className="lead-close">
                            X
                        </button>
                    </div>
                    <form className="lead-form">
                        <input className="lead-input" type="text" placeholder="First and Last name"/>
                        <input className="lead-input" type="text" placeholder="Phone number"/>
                        <input className="lead-input" type="text" placeholder="Email"/>
                        <input className="lead-button" type="button" value="Send the application"/>
                    </form>
                </div>
            }
        </div>
    )
}

import React from 'react'
import "./ACH.css"
import "../../index.css"
import { useFormik } from 'formik'

interface InitialValue{
  email:string,
  password:string
}

export const ACH = () => {
  const formik = useFormik<InitialValue>({
    initialValues:{
      email:'',
      password:''
    },onSubmit:({email,password})=>{
      alert(JSON.stringify({email,password}))
    }
  })
  return (
        <form className='form-ach'>
            <div className='title'>
                ACH <div style={{color:"#0b73c4",marginLeft:'15px',fontSize:'22px'}}>Direct Debit</div>
            </div>
            <div className='flex-row'>
                <input name='email' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='Account number'/>
                <input name='password' onChange={formik.handleChange} type="text" className='w-input input-password' placeholder='ABA routing number'/>
            </div>
            <input name='email' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='First and last name'/>
            <input name='password' onChange={formik.handleChange} type="text" className='w-input input-password' placeholder='Address(street, house number)'/>
            <input name='email' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='City'/>
            <input name='password' onChange={formik.handleChange} type="text" className='w-input input-password' placeholder='Postal code'/>
            <input name='email' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='Country'/>
            <input name='password' onChange={formik.handleChange} type="text" className='w-input input-password' placeholder='Email address'/>

            <label className="screen-check">
                <input type="file"/>
                add a payment screen
            </label>
        </form>
  )
}

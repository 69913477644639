import React, { Component, MouseEvent, CSSProperties } from "react";
import "../LastProjects/LastProjects.css"
import "../../index.css"

interface CarouselProps {
  _data: any[];
  itemWidth: number;
  itemHeight: number;
  itemSideOffsets: number;
  dragSpeed: number;
  children:React.ReactNode
}

interface CarouselState {
  isDown: boolean;
  startX: number | null;
  transLeftOffset: number | null;
  dragSpeed: number;
  
}

class Carousel extends Component<CarouselProps, CarouselState> {
  constructor(props: CarouselProps) {
    super(props);

    this.state = {
      isDown: false,
      startX: null,
      transLeftOffset: null,
      dragSpeed: props.dragSpeed
    };
  }
  cRef = React.createRef<HTMLDivElement>();
  handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const carousel = this.cRef.current as HTMLDivElement;

    e.persist();

    carousel.classList.add("active");

    const _startX = e.pageX - carousel.offsetLeft;
    const _transLeftOffset = this.giveMeIntValOf(
      (carousel.firstChild as HTMLDivElement ).style.transform
    );
    this.setState(
      {
        isDown: true,
        startX: _startX,
        transLeftOffset: _transLeftOffset
      },
      () => {
        const { startX, transLeftOffset, dragSpeed } = this.state;

        const x = e.pageX - carousel.offsetLeft;
        const walk = (x - startX!) * dragSpeed;

        (carousel.firstChild as HTMLDivElement ).style.cssText = `
        transform: translateX(${transLeftOffset! + walk}px);
        transition: transform 0.0s ease-in-out;
      `;
      }
    );
  };

  handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    this.handleSnap();
  };

  handleMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    this.handleSnap();
  };

  handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const { isDown, startX, transLeftOffset, dragSpeed } = this.state;
    const carousel = this.cRef.current as HTMLDivElement;

    if (!isDown) return;
    e.preventDefault();

    const x = e.pageX - carousel.offsetLeft;
    const walk = (x - startX!) * dragSpeed;

    (carousel.firstChild as HTMLDivElement ).style.transform = `translateX(${
      transLeftOffset! + walk
    }px)`;
  };

  handleSnap = () => {
    const { _data, itemWidth, itemSideOffsets } = this.props;
    const carousel = this.cRef.current as HTMLDivElement;

    this.setState({ isDown: false });
    carousel.classList.remove("active");

    const tempThresholdOffset = this.giveMeIntValOf(
      (carousel.firstChild as HTMLDivElement ).style.transform
    );
    const end =
      _data.length * (itemWidth + 2 * itemSideOffsets) -
      30 -
      carousel.offsetWidth;

    if (tempThresholdOffset < 0 || tempThresholdOffset > end) {
      this.setState({ isDown: false });
      (carousel.firstChild as HTMLDivElement ).style.cssText = `
        transform: translateX(${tempThresholdOffset < 0 ? 0 : end}px);
        transition: transform 0.5s cubic-bezier(.25,.72,.51,.96);
      `;
    }
  };

  giveMeIntValOf = (el: string) => {
    return parseInt(el.replace("translateX(", "").replace("px)", ""), 10);
  };

  render() {
    const { _data, itemWidth, itemHeight, itemSideOffsets } = this.props;

    const cWrapperStyle: CSSProperties = {
      width: `${_data.length * (itemWidth + 2 * itemSideOffsets)}px`,
      height: `${itemHeight}px`
    };

    return (
      <div
        className="carousel"
        ref={this.cRef}
        onMouseDown={this.handleMouseDown}
        onMouseLeave={this.handleMouseLeave}
        onMouseUp={this.handleMouseUp}
        onMouseMove={this.handleMouseMove}
      >
        <div
          className="cWrapper"
          style={{
            ...cWrapperStyle,
            transform: "translateX(3900px)"
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Carousel;


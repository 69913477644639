
import React,{useState} from 'react'
import '../../index.css'
import './LastProjects.css'
import Carousel from '../Carousel/Carousel'

export const LastProjects = () => {
    const [data] = useState<Array<{href:string,label:string}>>([
        {href:'https://isans.site/',label:'Isans site &amp; marketing'},{href:'https://www.canadadrives.ca/',label:'Canada Drives website'},
        {href:'https://www.maccosmetics.com/',label:'Mac Cosmetics site'},{href:'/',label:'ALD Transportation INC'},
        {href:'https://mvs-freight.com/',label:'MVS site &amp; recruiting'},{href:'/',label:'Pro Quality INC'},{href:'/',label:'North West Motors ChatBot'},
        {href:'',label:'GTS Transportation INC'},{href:'https://eutokitchen-eb2d3632caea.herokuapp.com/',label:'Europe Construction website'}
    ])
  return (
    <div data-aos="fade-down"
         data-aos-duration="1500" id="portfolio" style={{marginTop:'3rem'}} className='container'>
        <h2 className="h2">Our latest projects (click on project)</h2>
        <Carousel  itemHeight={580} itemWidth={580} dragSpeed={1.25} itemSideOffsets={8}  _data={data} >
            {
                data.reverse().map( (i:any,_i:number)=>(
                    <div draggable={false}  className={`column${_i+1} Item`}>
                        <a href={i.href} className="Item-text">
                            {i.label}
                        </a>
                    </div>
                ) )
            }
        </Carousel>
    </div>
  )
}

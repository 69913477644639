import React from 'react'
import "./Blog.css"
import { Item } from './Item'
import facebook from "../../img/facebook.png"
import instagram from "../../img/instagram.webp"
import telegram from "../../img/telegram-icon.svg"
import linkedIn from "../../img/linkedIn.webp"
import twitter from "../../img/twitter.png"
import youtube from "../../img/youtube.webp"
import {LeadForm} from "../LeadForm/LeadForm";

export const Blog = () => {
  return (
    <div className='bg-blog'>
      <div className='wrapper-content-blog'>
        <div  data-aos="fade-right"
              data-aos-duration="1500" className='nav-blog flex-col'>
          <input className='w-input input-search' placeholder='Search' type="text" />
          <div className='blog-flex-col-gap-20'>
            <div className='blog-flex-row-gap-20'>
              <div className='box-blue'>

              </div>
              <div className='content-text'>
                text text text text
              </div>
            </div>
            <div className='blog-flex-row-gap-20'>
              <div className='box-blue'>

              </div>
              <div className='content-text'>
                text text text text
              </div>
            </div>
            <div className='blog-flex-row-gap-20'>
              <div className='box-blue'>

              </div>
              <div className='content-text'>
                text text text text
              </div>
            </div>
            <div className='blog-flex-row-gap-20'>
              <div className='box-blue'>

              </div>
              <div className='content-text'>
                text text text text
              </div>
            </div>
          </div>
          <div className='flex-row-wrap'>
            <img src={facebook} className='icon' alt="" />
            <img src={instagram} className='icon' alt="" />
            <img src={telegram} className='icon' alt="" />
            <img src={linkedIn} className='icon' alt="" />
            <img src={twitter} className='icon' alt="" />
            <img src={youtube} className='icon' alt="" />
          </div>
        </div>
        <div className='flex-col'>
          <div  data-aos="fade-up"
                data-aos-duration="1500" className='must-read'>
            <div className='title'> 
              You should read
            </div>
            <div className='flex-col-gap-6'>
              <div className='blog-flex-row-gap-20'>
                <div className='box-blue'>
                  1
                </div>
                <div className='content-text'>
                  text text text text
                </div>
              </div>
              <div className='blog-flex-row-gap-20'>
                <div className='box-blue'>
                  2
                </div>
                <div className='content-text'>
                  text text text text
                </div>
              </div>
              <div className='blog-flex-row-gap-20'>
                <div className='box-blue'>
                  3
                </div>
                <div className='content-text'>
                  text text text text
                </div>
              </div>
              <div className='blog-flex-row-gap-20'>
                <div className='box-blue'>
                  4
                </div>
                <div className='content-text'>
                  text text text text
                </div>
              </div>
            </div>
          </div>
          <div  data-aos="fade-down"
                data-aos-duration="1500" className='flex-row-center'>
            <button className='active'>
            Latest
            </button>
            <button className='non-active'>
            Popular
            </button>
          </div>
          <div  className='items-blog'>
            <Item/>
            <Item/> 
            <Item/> 
            <Item/>
          </div>
        </div>
        <div  data-aos="fade-left"
              data-aos-duration="1500" className='flex-col'>
          <div  data-aos="fade-left"
                data-aos-duration="1500"  className='link-blog '>
              <div className='link-blog-img '>

              </div>
              <div className='link-blog-content '>
                <div className='title'>
                  TEXT TEXT  TEXT TEXT
                </div>
                <div className='font-14'>
                  TEXT TEXT  TEXT TEXT
                </div>
              </div>
          </div>
          <div  data-aos="fade-left"
                data-aos-duration="1500"  className='link-blog '>
              <div className='link-blog-img '>

              </div>
              <div className='link-blog-content '>
                <div className='title'>
                  TEXT TEXT  TEXT TEXT
                </div>
                <div className='font-14'>
                  TEXT TEXT  TEXT TEXT
                </div>
              </div>
          </div>
          <div  data-aos="fade-left"
                data-aos-duration="1500" className='link-blog '>
              <div className='link-blog-img '>

              </div>
              <div className='link-blog-content '>
                <div className='title'>
                  TEXT TEXT  TEXT TEXT
                </div>
                <div className='font-14'>
                  TEXT TEXT  TEXT TEXT
                </div>
              </div>
          </div>
        </div>

      </div>
      <LeadForm/>

    </div>

  )
}

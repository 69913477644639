import React from "react";
import "./OurServices.css"
export const OurServices = () => {
    return(
        <div className="service-wrapper">
            <div  data-aos="zoom-in"
                  data-aos-duration="1500" className="service-container">
                <div className="service-items">
                    <div  data-aos="fade-right"
                          data-aos-duration="1500" className="service-item">
                        <div className="text-item">
                            Unlimited technical support for projects that we have created
                        </div>
                    </div>
                    <div data-aos="fade-left"
                         data-aos-duration="1500" className="service-item">
                        <div className="text-item">
                            Guarantee of complete safety and high-speed product
                        </div>
                    </div>
                    <div data-aos="fade-right"
                         data-aos-duration="1500" className="service-item">
                        <div className="text-item">
                            We are focused on building partnerships. Therefore, we do everything to demonstrate our loyalty to our clients.
                        </div>
                    </div>
                    <div data-aos="fade-left"
                         data-aos-duration="1500" className="service-item">
                        <div className="text-item">
                            We work as an outsourcing company, so our prices are lower than those of counterparts in the American market.
                        </div>
                    </div>
                    <div data-aos="fade-right"
                         data-aos-duration="1500" className="service-item">
                        <div className="text-item">
                            We can implement any of your ideas in the IT sphere.
                        </div>
                    </div>
                    <div data-aos="fade-left"
                         data-aos-duration="1500" className="service-item">
                        <div className="text-item">
                            We always stick to our promises regarding guarantees, timelines, and scope!
                        </div>
                    </div>
                </div>
                <input data-aos="fade-top"
                       data-aos-duration="1500" className="service-button" type="button" value="Discuss the project"/>
            </div>
        </div>
    )
}
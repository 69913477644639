
import React, {useState} from 'react'
import './MainServices.css'
import analyticsINST from "../../img/InstagramAnatytics.png"
import analyticsFB from "../../img/FBAnalytics.png"
import analyticsGf04 from "../../img/Gfo4Analytics.png"
import RedditStats from '../../img/RedditStatistic.png'
import FaceBookStats from '../../img/FacebookStatistic.png'
import TelegramStats from '../../img/TelegramStatistic.png'
export const MainServices = () => {
    const [popUp,setPopUp] = useState<boolean>(false)
    return (
        <div className="MainServices-wrapper">
            <div className="MainServices-Promo">
                <div data-aos="zoom-out"
                     data-aos-duration="1500" className="Promo-content">
                    <div className="Promo-title">
                        Automation
                        <div style={{color:"#0b73c4"}}> of newsletters</div>
                    </div>
                    <div className="Promo-pretitle">
                        Create personalized campaigns with unlimited follow-ups to grow conversion rate and average order value.
                    </div>
                    <div className="flex-row-gap-75">
                        <input className="Promo-btn-primary" type="button" value="Launch"/>
                        <input className="Promo-btn" type="button" value="Promo video"/>
                    </div>
                </div>
            </div>
            <div className="Promo-bg-elements">
                <img data-aos="fade-right"
                     data-aos-duration="1500" id="facebook"  src={analyticsFB} alt=""/>
                <img data-aos="fade-left"
                     data-aos-duration="1500" id="telegram"  src={analyticsINST} alt=""/>
                <img data-aos="fade-right"
                     data-aos-duration="1500" id="reddit"  src={analyticsGf04} alt=""/>
            </div>
            <div className="content-bg-f2" >
                <div className="content-image">
                    <img src={TelegramStats} alt=""/>
                </div>
                <div data-aos="fade-left"
                    data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Telegram
                    </div>
                    <div className="content-pretitle">
                        Our flagship tools reaching over 3.5 million users in the USA. The core audience consists of Russian-speaking immigrants in the USA. The average user age is 23-45 years.
                    </div>
                    <div className="content-text">
                        It excels in recruiting Russian and Ukrainian-speaking personnel and in finding clients in logistics-related fields, dealerships, info business, and "grey areas."
                    </div>
                    <div className="content-text">
                        The average number of visits ranges from 80 to 300 per week. The monthly service fee is $300 for new clients, and $450 for subsequent months. It is not subscription-based.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content">
                <div data-aos="fade-right" data-aos-duration="1500"
                     data-aos-easing="ease-out-cubic" className="content-image">
                    <img src={RedditStats} alt=""/>
                </div>
                <div data-aos="flip-left"
                     data-aos-easing="ease-out-cubic"
                    data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        Reddit
                    </div>
                    <div className="content-pretitle">
                        A global social network that reaches over 16 million users in the USA. The core audience consists of 58% in the USA, 7% in Canada, and 6.5% in Mexico. Average гuser age - 18-35.
                    </div>
                    <div className="content-text">
                        The audience segmentation is designed in such a way that the most targeted audience there is for info-business, especially IT courses, and businesses not tied to location, particularly E-Commerce with established shipping to other states and/or countries, or automobile dealerships with licenses in different states.
                    </div>
                    <div className="content-text">
                        Regarding staff recruitment, the best niches are in the logistics business (non-local), the IT industry, сompanies that are hiring remote workers.
                    </div>
                    <div className="content-text">
                        The average number of visits ranges from 50 to 400 per week. The price for a monthly service is $200 for new clients and $300 for subsequent months.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            <div className="content-bg-f2" >
                <div className="content-image">
                    <img src={FaceBookStats} alt=""/>
                </div>
                <div data-aos="fade-right"
                     data-aos-duration="1500" className="flex-column-gap-20">
                    <div className="content-title-services">
                        Facebook
                    </div>
                    <div className="content-pretitle">
                        A social network that reaches over 50 million users in the USA. The core audience comprises 22% in the USA, 5% in China, and 4% in the Philippines. The average user age ranges from 25 to 55 years old.
                    </div>
                    <div className="content-text">
                        Essentially, due to its reach, it serves as a universal tool for customer acquisition in B2C niches and recruiting.
                    </div>
                    <div className="content-text">
                        It's the only platform with an appropriate moderation system but has an outdated coding structure, which complicates working with it.
                    </div>
                    <div className="content-text">
                        The average number of visits ranges from 150 to 600 per week. The price for a monthly service is $450 for new clients and $600 for subsequent months.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            {
                popUp && <div className="product-lead-form">
                    <div className='flex-row-lead'>
                        <div className="lead-title">
                            <div style={{color:'#0b73c4'}}>Let's discuss</div> your project
                            <div className="lead-pre-title">
                                Fill out the form and  <br/>we will contact you within a day
                            </div>
                        </div>
                        <button onClick={()=>setPopUp(false)} className="lead-close">
                            X
                        </button>
                    </div>
                    <form className="lead-form">
                        <input className="lead-input" type="text" placeholder="First and Last name"/>
                        <input className="lead-input" type="text" placeholder="Phone number"/>
                        <input className="lead-input" type="text" placeholder="Email"/>
                        <input className="lead-button" type="button" value="Send the application"/>
                    </form>
                </div>
            }

        </div>
    )
}

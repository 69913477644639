import React from 'react'
import "./BlogItem.css"
import { NavLink } from 'react-router-dom'
import arrow from "../../img/arrow-Back.png"
import comment from "../../img/comment.png"
import views from "../../img/views.png"

export const BlogItem = () => {
  return (
    <div className='bg-blog'>
        <div className='wrapper-content'>
            <div className='w-845 '>
                <div  data-aos="fade-left"
                      data-aos-duration="1500" className='h-50'>
                    <NavLink to={'/blog/'} className={'blog-link'}> <img src={arrow} alt="" /> All articles</NavLink>
                </div>
                <div className='blog-content w-845'>
                    <div  data-aos="fade-down"
                          data-aos-duration="1500" className='blog-title'>
                        <div className='flex-gap-20'>
                            <div className='count'> 
                                <img src={views} alt="" />
                                12345
                            </div>
                            <div className='count'>
                                <img src={comment} alt="" />
                                0
                            </div>
                        </div>
                        <div>
                        TITLE TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT
                        </div>
                    </div>
                    <div  className='img-title'>

                    </div>
                    <div  data-aos="fade-left"
                          data-aos-duration="1500" className="pre-title">
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                    </div>
                    <div data-aos="fade-up"
                         data-aos-duration="1500" className='content-title'>
                        TITLE TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT
                    </div>
                    <div data-aos="fade-right"
                         data-aos-duration="1500" className="pre-title">
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                    </div>
                    <div data-aos="zoom-in"
                         data-aos-duration="1500" className='content-title'>
                        #1 TEXT TEXT TEXT TEXT TEXT
                    </div>
                    <div data-aos="fade-left"
                         data-aos-duration="1500" className="pre-title">
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                        <div className="content-img">

                        </div>
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                    </div>
                    <div data-aos="zoom-in"
                         data-aos-duration="1500" className='content-title'>
                        #2 TEXT TEXT TEXT TEXT TEXT
                    </div>
                    <div data-aos="fade-right"
                         data-aos-duration="1500" className="pre-title">
                        <div className="content-img">

                        </div>
                        <div>
                            text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text 
                        </div>
                    </div>
                </div>
                <form data-aos="zoom-in"
                      data-aos-duration="1500" className='w-845 blog-form'>
                    <div className='flex-col-gap-20 '>
                        <input type="text" className="w-input" placeholder='+380 00 00 000'/>
                        <input type="text" className="w-input" placeholder='Enter your name'/>
                        <input type="text" className="w-input" placeholder='Enter your email'/>
                    </div>
                    <div className='flex-col-gap-20 '>
                        <textarea placeholder='Enter your comment' className="w-textarea"></textarea>
                        <input type="button" value="send comment" className="w-button" />
                    </div>
                </form>
                <div data-aos="fade-right"
                     data-aos-duration="1500" className='w-845 blog-comment'>
                    <div className='title'>
                        <img src="" alt="" />
                        <div>
                            user name
                        </div>
                    </div>
                    <div className='text-content'>
                        text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text
                    </div>
                </div>
                <div data-aos="fade-left"
                    data-aos-duration="1500" className='w-845 blog-comment'>
                    <div className='title'>
                        <img src="" alt="" />
                        <div>
                            user name
                        </div>
                    </div>
                    <div className='text-content'>
                        text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text
                    </div>
                </div>
                <div data-aos="fade-right"
                     data-aos-duration="1500" className='w-845 blog-comment'>
                    <div className='title'>
                        <img src="" alt="" />
                        <div>
                            user name
                        </div>
                    </div>
                    <div className='text-content'>
                        text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

import React, {useState} from "react";
import '../CustomDevelop/CustomDevelop.css'
import CustomDevelop1 from "../../img/CustomDevelop1.png";
import CustomDevelop2 from "../../img/CustomDevelop2.png";
import CustomDevelop3 from "../../img/CustomDevelop3.png";
import recrut from  '../../img/recruitment-process.png'
const CustomDevelop = () => {
    const [popUp,setPopUp] = useState<boolean>(false)
    return (
        <div className="MainServices-wrapper">
            <div className="MainServices-Promo">
                <div  data-aos="zoom-out"
                      data-aos-duration="1500" className="Promo-content">
                    <div className="Promo-title">
                        Recruting
                        <div style={{color:"#0b73c4",marginLeft:'5px'}}></div>
                    </div>
                    <div className="Promo-pretitle">
                        Crafting Digital Experiences: <br/> Custom Development at Its Best
                    </div>
                    <div className="flex-row-gap-75">
                        <input className="Promo-btn-primary" type="button" value="Launch"/>
                        <input className="Promo-btn" type="button" value="Promo video"/>
                    </div>
                </div>
                <div className="Promo-bg-elements">
                    <img data-aos="fade-right"
                         data-aos-duration="1500"  id="facebook"  src={CustomDevelop1} alt=""/>
                    <img  data-aos="fade-left"
                          data-aos-duration="1500"id="telegram"  src={CustomDevelop2} alt=""/>
                    <img data-aos="fade-right"
                         data-aos-duration="1500"  id="reddit"  src={CustomDevelop3} alt=""/>
                </div>
            </div>
            <div className="content">
                <div className="content-image">
                    <img src={recrut} alt=""/>
                </div>
                <div data-aos="flip-left"
                     data-aos-easing="ease-out-cubic"
                     data-aos-duration="1500" className="flex-column-gap-20 box-content">
                    <div className="content-title-services">
                        Outsource recruiting
                    </div>
                    <div className="content-text">
                        Let's make your business thrive with outsourced recruiting! After all, this is the way to find and hire the best specialists for your company. Moreover, it can minimize one of the most cost-intensive items in any company's budget - personnel.
                    </div>
                    <div className="content-text">
                        Why choose us? Because we have no location restrictions. We will find the personnel you need - wherever you need them to be. They will have the required qualifications and a long-term commitment to work for you.
                    </div>
                    <div className="content-text">
                        Interested? Then write to us, and let's discuss all the details.
                    </div>
                    <button onClick={()=>setPopUp(!popUp)} className='content-lead-btn'>
                        Launch
                    </button>
                </div>
            </div>
            {
                popUp && <div className="product-lead-form">
                    <div className='flex-row-lead'>
                        <div className="lead-title">
                            <div style={{color:'#0b73c4'}}>Let's discuss</div> your project
                            <div className="lead-pre-title">
                                Fill out the form and  <br/>we will contact you within a day
                            </div>
                        </div>
                        <button onClick={()=>setPopUp(false)} className="lead-close">
                            X
                        </button>
                    </div>
                    <form className="lead-form">
                        <input className="lead-input" type="text" placeholder="First and Last name"/>
                        <input className="lead-input" type="text" placeholder="Phone number"/>
                        <input className="lead-input" type="text" placeholder="Email"/>
                        <input className="lead-button" type="button" value="Send the application"/>
                    </form>
                </div>
            }
        </div>
    )
}
export default CustomDevelop;

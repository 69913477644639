import React from "react";
import "./LeadForm.css"

export const LeadForm = () => {
  return(
      <div id="leadForm" className="lead-container">
              <div className="lead-title">
                  <div style={{color:'#0b73c4'}}>Let's discuss</div> your project
                  <div className="lead-pre-title">
                      Fill out the form and  <br/>we will contact you within a day
                  </div>
              </div>
          <form className="lead-form">
              <input className="lead-input" type="text" placeholder="First and Last name"/>
              <input className="lead-input" type="text" placeholder="Phone number"/>
              <input className="lead-input" type="text" placeholder="Email"/>
              <input className="lead-button" type="button" value="Send the application"/>
          </form>
      </div>
  )
}
import React from 'react'
import "../../index.css"
import telegram from "../../img/telegram-icon.svg"
import whatsapp from "../../img/whatsapp-icon.svg"


export const Foother = () => {
  return (
    <div className="section-footer ">
        <div data-w-id="8f53e7fa-56c8-51a7-a582-5d3a77815ed7" className="z footer">
        <div className="w-layout-grid footer-grid-big">
            <div id="w-node-_8f53e7fa-56c8-51a7-a582-5d3a77815ed9-5b8bed2b" className="fon-for-items">
                <div className="mini-podz">About G&amp;M</div>
                <a href="#about" className="link-footer">About</a><a href="#tariffs" className="link-footer">Tariffs</a>
            </div>
            <div id="w-node-_8f53e7fa-56c8-51a7-a582-5d3a77815ee2-5b8bed2b" className="fon-for-items">
                <div className="mini-podz">Politics &amp; Developing</div>
                <a href="privacy.html" className="link-footer">Privacy Policy</a>
            </div>
            <div id="w-node-_8f53e7fa-56c8-51a7-a582-5d3a77815eeb-5b8bed2b" className="fon-for-items">
                <div className="mini-podz">For Customers</div>
                <a href="#portfolio" className="link-footer">Portfolio</a><a href="#testimonials" className="link-footer">Testimonials</a>
            </div>
            <div id="w-node-_8f53e7fa-56c8-51a7-a582-5d3a77815ef4-5b8bed2b" className="fon-for-items">
                <a href="mailto:info@mgdevelopment.co?subject=Hello!%20I%20would%20like%20to%20work%20with%20you!" className="mail">info@mgdevelopment.co</a>
                <div className="social-media-links">
                    <a href="https://t.me/G_M_Inc" className="social-media-link w-inline-block"><img src={telegram} loading="lazy" width="40" alt="telegram" className="social-media-link"/></a>
                    <a href="https://api.whatsapp.com/send?phone=13236385468" className="w-inline-block"><img src={whatsapp} loading="lazy" alt="whatsapp" className="social-media-link"/></a>
                </div>
            </div>
        </div>
        <div className="paragraph-mini-2">© G&amp;M 2018 - 2023</div>
        </div>
    </div>
  )
}

import React from 'react'
import "./Paypal.css"
import "../../index.css"
import { useFormik } from 'formik'

interface InitialValue{
  email:string,
  password:string
}

export const Paypal = () => {
  const formik = useFormik<InitialValue>({
    initialValues:{
      email:'',
      password:''
    },onSubmit:({email,password})=>{
      alert(JSON.stringify({email,password}))
    }
  })
  return (
        <form className='form-paypal'>
            <div className='title'>
                PayPal <div style={{color:"#0b73c4",marginLeft:'15px',fontSize:'22px'}}> Payment</div>
            </div>
            <input name='name' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='First and last name'/>
            <input name='email' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='Email address'/>
            <input name='Companyname' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='Company name'/>
            <input name='website' onChange={formik.handleChange} type="text" className='w-input input-password' placeholder='Website'/>
            <input onClick={(e:any)=>formik.handleSubmit(e)} type="button" className='button-form w-button' value="Pay with PayPal" />
            <label className="screen-check">
                <input type="file"/>
                add a payment screen
            </label>

        </form>
  )
}

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import DatePicker from 'antd/es/date-picker'
import "./Analytics.css"

export const Analytics = () => {
    const { RangePicker } = DatePicker
    const [formRobot,setformRobot] = useState<boolean>(false)
    const [unique,setunique] = useState<boolean>(false)
  return (
    <div className='bg-full'>
        <div className="Analytics-header">
            <div className='flex-col'>
                <div className='flex'>
                    <div className='Analytics-logo-user'>

                    </div>
                    <div className='Analytics-title'>
                        Altex Logiistics
                    </div>
                </div>
                <div className='flex text-18' style={{marginLeft:'20px'}}>
                    <NavLink to={'/'}>Home</NavLink>
                    <div> /</div>
                    Analytics
                </div>
            </div>
            <div className='flex-col'>
                <RangePicker/>
                <div className='flex'>
                    <button onClick={()=>setformRobot(!formRobot)} className={formRobot?'button-pick-active':'button-pick'}>
                        Filter Robots
                    </button>
                    <button onClick={()=>setunique(!unique)} className={unique?'button-pick-active':'button-pick'}>
                        Unique
                    </button>
                </div>
            </div>
        </div>
        <div className='Analytics-content'>
            <div className='title-big'>
                <div className='title-bold'>
                    101
                </div>
                Total Clicks
            </div>
            <div className="Analytics-table">
                <div className='title'>
                    <div>
                        Country
                    </div>
                    <div>
                        Clicks
                    </div>
                    <div className='-ml-30'>
                        Clicks %
                    </div>
                </div>
                <div className='element'>
                    <div>
                        United States
                    </div>
                    <div>
                        90
                    </div>
                    <div>
                        89%
                    </div>
                    <div className='progress'>

                    </div>
                </div>
                <div className='element'>
                    <div>
                        United States
                    </div>
                    <div>
                        90
                    </div>
                    <div>
                        89%
                    </div>
                    <div className='progress'>

                    </div>
                </div>
                <div className='element'>
                    <div>
                        United States
                    </div>
                    <div>
                        90
                    </div>
                    <div>
                        89%
                    </div>
                    <div className='progress'>

                    </div>
                </div>
                <div className='element'>
                    <div>
                        United States
                    </div>
                    <div>
                        90
                    </div>
                    <div>
                        89%
                    </div>
                    <div className='progress'>

                    </div>
                </div>
                <div className='element'>
                    <div>
                        United States
                    </div>
                    <div>
                        90
                    </div>
                    <div>
                        89%
                    </div>
                    <div className='progress'>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

import React from 'react'
import "./Login.css"
import "../../index.css"
import { useFormik } from 'formik'

interface InitialValue{
  email:string,
  password:string
}

export const Login = () => {
  const formik = useFormik<InitialValue>({
    initialValues:{
      email:'',
      password:''
    },onSubmit:({email,password})=>{
      alert(JSON.stringify({email,password}))
    }
  })
  return (
    <div className='login-wrapper '>
        <form  data-aos="fade-down"
               data-aos-duration="1500" className='form-login'>
            <div className='title'>
                Log <div style={{color:"#0b73c4"}}> In</div>
            </div>
            <input name='email' onChange={formik.handleChange} type="text" className='w-input input-email' placeholder='Input your email'/>
            <input name='password' onChange={formik.handleChange} type="text" className='w-input input-password' placeholder='Input your password'/>
            <input onClick={(e:any)=>formik.handleSubmit(e)} type="button" className='button-form w-button' value="Log in" />
        </form>
        <div>
        </div>
    </div>
  )
}

import React from 'react'
import '../../index.css'


export const Main = () => {
  return (
    <div style={{marginBottom:'38.8rem'}}>
        <section data-w-id="f4e9d607-9871-21de-f4e4-cfe15d91dbc3" className="hero-section wf-section">
        <div className='Promo'>
            <div className="container ">
                <div className="w-layout-grid grid">
                <div className="left-side">
                    <h1 className="h1">G&amp;M - <h3 className="color paragraph-cta-1">your derect line to success!</h3></h1>
                    <h5 className=" paragraph-cta-2">We make anything, that would be needed to create a solid basis of your business development!</h5>
                    <a href="/" className="btn w-button">Make it real</a>
                </div>
                <div id="w-node-_20098a71-b80e-4ae5-b7be-659d983fec5c-5b8bed2b" className="right-side">
                    </div>
                </div>
            </div>
        </div>
    </section >
        {/*<div>*/}
        {/*    <h2 className="h2">Our partners:</h2>*/}
        {/*    <Carousel autoplay speed={8} className="slideshow-container">*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider1} alt=''   />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider2} alt=''   />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider3} alt=''   />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider4} alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider5} alt=''   />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider6}  alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider7}  alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider8}  alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider9}  alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider10} alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider11}  alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider12}  alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider13} alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider15}  alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider16}  alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider17}  alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider18}  alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider19} alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider20}   alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider21} alt=''  />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides ">*/}
        {/*            <img src={slider22}  alt='' />*/}
        {/*        </div>*/}
        {/*        <div className="mySlides " >*/}
        {/*            <img src={slider23} alt='' />*/}
        {/*        </div>*/}
        {/*    </Carousel>*/}
        {/*</div>*/}
    </div>
  )
}

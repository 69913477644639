import React from 'react'
import "./Blog.css"
import { NavLink } from 'react-router-dom'

export const Item = () => {
  return (
    <NavLink to={'/blog/1'} className='Item-blog'>
        <div  data-aos="zoom-in"
              data-aos-duration="1500" className='Item-blog-content'>
            <div className='title'>
            TEXT TEXT TEXT TEXT
            </div>
            <div className='font-14'>
                text text text text text text text text text text
                text text text text text text text text text text
            </div>
        </div>
        <div className='Item-blog-img'>

        </div>
    </NavLink>
  )
}
